import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import Messages from '../components/Messages';

const HomePageBase = () => (
  <div className="page-content pb-0">
    <div className="card" data-card-height="cover" style={{height: '100vh'}}>
      <div className="card-center">
        <div className="content mr-5 ml-5 mb-0">
          <h1>Home Page</h1>
          <p>The Home Page is accessible by every signed in user.</p>

          {/* <Messages /> */}
        </div>
      </div>
    </div>
  </div>
);

const condition = authUser => !!authUser;

const HomePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePageBase);

export default () => (
  <Layout>
    <HomePage />
  </Layout>
);
